import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import PrimaryExample from '@workday/canvas-kit-docs/dist/mdx/react/button/button/examples/Primary.tsx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const ExampleCodeBlock = makeShortcode("ExampleCodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The primary way you will interact with Canvas will be with our
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit"
      }}>{`Canvas Kit React library`}</a>{`. Canvas Kit provides a set of
components and primitives that can be used to implement user experiences consistent with the rest of
Workday. As of v11, our library relies on the usage of our
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-tokens"
      }}>{`Tokens`}</a>{` package for styling.`}</p>
    <h2 {...{
      "id": "canvas-kit"
    }}>{`Canvas Kit`}</h2>
    <p>{`We support `}<a parentName="p" {...{
        "href": "#installation"
      }}>{`installation`}</a>{` for existing projects and `}<a parentName="p" {...{
        "href": "#bootstrapping"
      }}>{`bootstrapping`}</a>{`
in a new project.`}</p>
    <h3 {...{
      "id": "installation"
    }}>{`Installation`}</h3>
    <p>{`For an existing project, add the Canvas Kit React dependency with your preferred package manager.`}</p>
    <p>{`With Yarn:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`> yarn add @workday/canvas-kit-react
`}</code></pre>
    <p>{`With npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`> npm install --save @workday/canvas-kit-react
`}</code></pre>
    <h3 {...{
      "id": "canvas-tokens"
    }}>{`Canvas Tokens`}</h3>
    <p>{`This package provides Canvas design tokens for web applications. As of v11 our components rely on
our `}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-tokens"
      }}>{`Tokens Package`}</a>{`. You `}<strong parentName="p">{`must`}</strong>{` install and import the
variables to ensure our components are styled correctly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`> yarn add @workday/canvas-tokens-web
`}</code></pre>
    <p>{`Import the variables at the top level of your application to prevent duplicate imports and avoid
unintentional overwrites. Import them in a native CSS file or in a JavaScript / TypeScript file as
shown below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* index.css */
@import '@workday/canvas-tokens-web/css/base/_variables.css';
@import '@workday/canvas-tokens-web/css/system/_variables.css';
@import '@workday/canvas-tokens-web/css/brand/_variables.css';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// index.ts
import '@workday/canvas-tokens-web/css/base/_variables.css';
import '@workday/canvas-tokens-web/css/system/_variables.css';
import '@workday/canvas-tokens-web/css/brand/_variables.css';
`}</code></pre>
    <p>{`For more information, view our
`}<a parentName="p" {...{
        "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-getting-started--docs"
      }}>{`Canvas Tokens docs`}</a>{`.`}</p>
    <h3 {...{
      "id": "bootstrapping"
    }}>{`Bootstrapping`}</h3>
    <InternalContent mdxType="InternalContent">
      <p>{`For a new project, we recommend using Workday's
`}<a parentName="p" {...{
          "href": "//quantum.workday.build/products/qbit/"
        }}>{`Qbit CLI tool`}</a>{` to bootstrap it. You can think of Qbit like
Workday‘s very own `}<a parentName="p" {...{
          "href": "//create-react-app.dev/"
        }}>{`Create React App`}</a>{`, with support for standalone apps or
apps/widgets built within the Workday context.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`> npx @workday/qbit@latest create
`}</code></pre>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`Canvas Kit is built with React and
`}<a parentName="p" {...{
          "href": "https://create-react-app.dev/docs/getting-started"
        }}>{`Create React App`}</a>{` is recommended for
bootstrapping a new React project.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "canvas-provider"
    }}>{`Canvas Provider`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<CanvasProvider>`}</inlineCode>{` is required for proper branding support. Furthermore, if you use Emotion for
styling your components, the `}<inlineCode parentName="p">{`<CanvasProvider>`}</inlineCode>{` ensures your styles will merge as expected. Note:
Custom use of `}<inlineCode parentName="p">{`<CacheProvider>`}</inlineCode>{` provided by Emotion is not supported. `}<inlineCode parentName="p">{`@workday/canvas-kit-styling`}</inlineCode>{`
owns the creating of the cache reference. This ensures both static styling and Emotion’s dynamic
styling solutions work together. In most cases you'll want to wrap your application at the root
level in `}<inlineCode parentName="p">{`<CanvasProvider>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import * as React from 'react';
import {
  CanvasProvider,
  ContentDirection,
  PartialEmotionCanvasTheme,
  useTheme,
} from '@workday/canvas-kit-react/common';

// Ensure CSS variables are defined. You Can also do this at the root index.css
import '@workday/canvas-tokens-web/css/base/_variables.css';
import '@workday/canvas-tokens-web/css/brand/_variables.css';
import '@workday/canvas-tokens-web/css/system/_variables.css';

export const App = () => {
  // useTheme is filling in the Canvas theme object if any keys are missing
  const canvasTheme: PartialEmotionCanvasTheme = useTheme({
    canvas: {
      // Switch to \`ContentDirection.RTL\` to change direction
      direction: ContentDirection.LTR,
    },
  });

  return (
    <CanvasProvider theme={canvasTheme}>
      <>
        <main>
          <p>Get Started With Canvas Kit</p>
        </main>
      </>
    </CanvasProvider>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <p>{`Now you're ready to import our components.`}</p>
    <ExampleCodeBlock code={PrimaryExample} showCode={true} mdxType="ExampleCodeBlock" />
    <p>{`For more information on how to use Canvas Kit components, check out their documentation in the
`}<a parentName="p" {...{
        "href": "/components/overview"
      }}>{`Components section`}</a>{`.`}</p>
    <h3 {...{
      "id": "resources"
    }}>{`Resources`}</h3>
    <p>{`For more documentation, helpful links and other developer resources, check out the
`}<a parentName="p" {...{
        "href": "/get-started/for-developers/resources"
      }}>{`Resources page`}</a>{`.`}</p>
    <h3 {...{
      "id": "reporting-a-bug"
    }}>{`Reporting a Bug`}</h3>
    <p>{`If you spot a bug, inconsistency, or typo, please
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/issues/new?labels=bug&template=bug.md"
      }}>{`open a bug issue`}</a>{`.
Better yet, submit a pull request to addresses it.`}</p>
    <h3 {...{
      "id": "feature-requests"
    }}>{`Feature Requests`}</h3>
    <p>{`If you have an idea, we would love to hear about it. The best way to suggest a feature is to
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/issues/new?labels=feature&template=feature.md"
      }}>{`open a feature issue`}</a>{`.
The Canvas Kit core team will take a look and discuss it with you.`}</p>
    <h3 {...{
      "id": "questions"
    }}>{`Questions`}</h3>
    <InternalContent mdxType="InternalContent">
      <p>{`If you have a question, we are here to help! Reach out to the Canvas Kit core team on the
`}<a parentName="p" {...{
          "href": "https://workday-dev.slack.com/archives/C8X0W5AQH"
        }}>{`#ask-canvas-kit`}</a>{` channel on Slack.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`If you have a question, we are here to help! Start a new
`}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit/discussions"
        }}>{`discussion`}</a>{` to be connected to one of our team
members.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "contributing"
    }}>{`Contributing`}</h3>
    <p>{`Want to contribute to Canvas Kit? Please read our
`}<a parentName="p" {...{
        "href": "/get-started/for-developers/contributing-to-canvas"
      }}>{`contributing guidelines`}</a>{` to find out more and
how to get started.`}</p>
    <h3 {...{
      "id": "versioning"
    }}>{`Versioning`}</h3>
    <p>{`Canvas Kit follows `}<a parentName="p" {...{
        "href": "https://semver.org/"
      }}>{`semantic versioning`}</a>{` and is enforced automatically by
`}<a parentName="p" {...{
        "href": "https://www.conventionalcommits.org/"
      }}>{`conventional commits`}</a>{` (see
`}<a parentName="p" {...{
        "href": "/get-started/for-developers/contributing-to-canvas#commit-message-format"
      }}>{`"Commit Message Format"`}</a>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      